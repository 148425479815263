window._ = require('lodash');
window.Vue = require('vue');
import VueToastr from 'vue-toastr';

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.prototype.$http = axios

import vuetify from './plugins/vuetify'

import Login from './components/Login'
Vue.use(VueToastr)

Vue.component('Login', Login)

new Vue({
    vuetify
}).$mount('#login')
