<template>
    <v-app id="login">
        <v-content>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="elevation-12">
                            <v-toolbar color="primary" dark flat>
                                <v-toolbar-title>Вход</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        v-model="form.email"
                                        label="Email"
                                        name="email"
                                        prepend-icon="mdi-account"
                                        type="text"
                                    />

                                    <v-text-field
                                        v-model="form.password"
                                        label="Пароль"
                                        name="password"
                                        prepend-icon="mdi-lock"
                                        type="password"
                                    />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn color="primary" @click="login">Войти</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        props: {
            source: String
        },
        data: () => ({
            form: {
                email: "",
                password: ""
            }
        }),
        methods: {
            login() {
                this.$http.post("/ajax-login", this.form)
                    .then(res => window.location.href = res.data.redirect)
                    .catch(error => this.$toastr.e(error.response.data.message))
            }
        }
    };
</script>
